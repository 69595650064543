import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { Button, Table, Form } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import NavBar from '../NavBar/navbar';
import './ClientesConAsignaciones.css';

const estadosAsignacion = ["Pendiente", "Completado", "Por Iniciar"];

function ClientesConAsignaciones() {
  const [clientes, setClientes] = useState([]);
  const [asignaciones, setAsignaciones] = useState([]);
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const db = getDatabase();
    const clientsRef = ref(db, 'Clientes');
    onValue(clientsRef, (snapshot) => {
      const data = snapshot.val();
      const clientsList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      const filteredClients = clientsList.filter(cliente => cliente.estado === 'Activo' && cliente.obligaciones && cliente.obligaciones.length > 0);
      setClientes(filteredClients);

      // Recolectar todas las asignaciones únicas
      const allAsignaciones = [...new Set(filteredClients.flatMap(cliente => cliente.obligaciones))];
      setAsignaciones(allAsignaciones);
    });
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setRedirect(true);
      console.log('Sesión cerrada');
    } catch (error) {
      console.error('Error al cerrar sesión', error);
    }
  };

  if (redirect) {
    return <Navigate to="/signin" />;
  }

  return (
    <div>
      <NavBar handleSignOut={handleSignOut} />
      <br />
      <div className="table-container">
        <h2>Clientes con Asignaciones</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Teléfono</th>
              <th>Empresa</th>
              <th>Correo Electrónico</th>
              {asignaciones.map((asignacion, index) => (
                <th key={index}>{asignacion}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {clientes.map(cliente => (
              <tr key={cliente.id}>
                <td>{cliente.nombre}</td>
                <td>{cliente.telefono}</td>
                <td>{cliente.tipoCliente}</td>
                <td>{cliente.correo}</td>
                {asignaciones.map((asignacion, index) => {
                  const obligacion = cliente.obligaciones.find(ob => ob === asignacion);
                  return (
                    <td key={index}>
                      {obligacion ? (
                        <Form.Control as="select" value={obligacion.estado} disabled className="estado-dropdown">
                          {estadosAsignacion.map(estado => (
                            <option key={estado} value={estado}>{estado}</option>
                          ))}
                        </Form.Control>
                      ) : (
                        <span>N/A</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

export default ClientesConAsignaciones;