import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update, remove } from 'firebase/database';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { Button, Table, Form, Modal } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import NavBar from '../NavBar/navbar';
import './DeclaracionImpuestos.css';

function DeclaracionImpuestos() {
  const [clientes, setClientes] = useState([]);
  const [asignacionesPorCliente, setAsignacionesPorCliente] = useState([]);
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentAsignacion, setCurrentAsignacion] = useState(null);

  useEffect(() => {
    const db = getDatabase();
    const clientsRef = ref(db, 'Clientes');
    const asignacionesRef = ref(db, 'AsignacionesPorCliente');

    onValue(clientsRef, (snapshot) => {
      const data = snapshot.val();
      const clientsList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      setClientes(clientsList);
    });

    onValue(asignacionesRef, (snapshot) => {
      const data = snapshot.val();
      const asignacionesList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      setAsignacionesPorCliente(asignacionesList);
    });
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setRedirect(true);
      console.log('Sesión cerrada');
    } catch (error) {
      console.error('Error al cerrar sesión', error);
    }
  };

  const handleCheckboxChange = (asignacionId, checked) => {
    const db = getDatabase();
    const asignacionRef = ref(db, `AsignacionesPorCliente/${asignacionId}`);
    update(asignacionRef, { completado: checked });

    // Update the local state for immediate feedback
    setAsignacionesPorCliente(prevState => 
      prevState.map(asignacion =>
        asignacion.id === asignacionId ? { ...asignacion, completado: checked } : asignacion
      )
    );
  };

  const handleEditClick = (asignacion) => {
    setCurrentAsignacion(asignacion);
    setShowModal(true);
  };

  const handleDeleteClick = (asignacionId) => {
    const db = getDatabase();
    const asignacionRef = ref(db, `AsignacionesPorCliente/${asignacionId}`);
    remove(asignacionRef);

    // Update the local state to remove the deleted assignment
    setAsignacionesPorCliente(prevState =>
      prevState.filter(asignacion => asignacion.id !== asignacionId)
    );
  };

  const handleModalSave = () => {
    const db = getDatabase();
    const asignacionRef = ref(db, `AsignacionesPorCliente/${currentAsignacion.id}`);
    update(asignacionRef, currentAsignacion);
    setShowModal(false);
  };

  if (redirect) {
    return <Navigate to="/signin" />;
  }

  const getClienteData = (clienteId) => {
    return clientes.find(cliente => cliente.id === clienteId) || {};
  };

  // Agrupar asignaciones por cliente
  const asignacionesAgrupadas = asignacionesPorCliente.reduce((acc, asignacion) => {
    const clienteId = asignacion.clienteId;
    if (!acc[clienteId]) {
      acc[clienteId] = [];
    }
    acc[clienteId].push(asignacion);
    return acc;
  }, {});

  return (
    <div>
      <NavBar handleSignOut={handleSignOut} />
      <br />
      <div className="table-container">
        <h2>Declaración de Impuestos y Aportes</h2>
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>NIT</th>
              <th>Nombre o Razón Social</th>
              <th>Obligación</th>
              <th>Fecha Límite de Emisión</th>
              <th>Nro Talonario Impreso</th>
              <th>Envío Planillas Min. Trabajo</th>
              <th>RC IVA</th>
              <th>Completado</th>
              <th>Acciones</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(asignacionesAgrupadas).map(clienteId => {
              const cliente = getClienteData(clienteId);
              const asignaciones = asignacionesAgrupadas[clienteId];
              return (
                <React.Fragment key={clienteId}>
                  {asignaciones.map((asignacion, index) => (
                    <tr key={asignacion.id}>
                      {index === 0 && (
                        <>
                          <td rowSpan={asignaciones.length}>{cliente.nit || 'N/A'}</td>
                          <td rowSpan={asignaciones.length}>{cliente.nombre || 'N/A'}</td>
                        </>
                      )}
                      <td>{asignacion.obligacion}</td>
                      <td>{asignacion.fechaLimite}</td>
                      <td>{asignacion.nroTalonario}</td>
                      <td>{asignacion.envioPlanillas}</td>
                      <td>{asignacion.rcIva}</td>
                      <td>
                        <Form.Check 
                          type="checkbox" 
                          checked={asignacion.completado || false} 
                          disabled={asignacion.completado}
                          onChange={(e) => handleCheckboxChange(asignacion.id, e.target.checked)}
                        />
                      </td>
                      <td>
                        <Button variant="link" onClick={() => handleEditClick(asignacion)}>
                          <FontAwesomeIcon icon={faEdit} />
                        </Button>
                        <Button variant="link" onClick={() => handleDeleteClick(asignacion.id)}>
                          <FontAwesomeIcon icon={faTrash} />
                        </Button>
                      </td>
                    </tr>
                  ))}
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      </div>

      {currentAsignacion && (
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Modificar Asignación</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group>
                <Form.Label>Fecha Límite</Form.Label>
                <Form.Control
                  type="date"
                  value={currentAsignacion.fechaLimite}
                  onChange={(e) => setCurrentAsignacion({ ...currentAsignacion, fechaLimite: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Nro Talonario Impreso</Form.Label>
                <Form.Control
                  type="text"
                  value={currentAsignacion.nroTalonario}
                  onChange={(e) => setCurrentAsignacion({ ...currentAsignacion, nroTalonario: e.target.value })}
                />
              </Form.Group>
              <Form.Group>
                <Form.Label>Envío Planillas Min. Trabajo</Form.Label>
                <Form.Control
                  as="select"
                  value={currentAsignacion.envioPlanillas}
                  onChange={(e) => setCurrentAsignacion({ ...currentAsignacion, envioPlanillas: e.target.value })}
                >
                  <option value="no">No</option>
                  <option value="si">Sí</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>RC IVA</Form.Label>
                <Form.Control
                  as="select"
                  value={currentAsignacion.rcIva}
                  onChange={(e) => setCurrentAsignacion({ ...currentAsignacion, rcIva: e.target.value })}
                >
                  <option value="trim">Trim</option>
                  <option value="manual">Manual</option>
                  <option value="linia/09">Linia/09</option>
                  <option value="linia/08">Linia/08</option>
                  <option value="baja">Baja</option>
                </Form.Control>
              </Form.Group>
              <Form.Group>
                <Form.Label>Completado</Form.Label>
                <Form.Check 
                  type="checkbox"
                  checked={currentAsignacion.completado || false}
                  onChange={(e) => setCurrentAsignacion({ ...currentAsignacion, completado: e.target.checked })}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Cancelar</Button>
            <Button variant="primary" onClick={handleModalSave}>Guardar Cambios</Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default DeclaracionImpuestos;