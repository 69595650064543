import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, push } from 'firebase/database';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { Button, Form, Container, Modal } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import NavBar from '../NavBar/navbar';
import './AsignacionesporCliente.css';

function AsignacionesPorCliente() {
  const [clientes, setClientes] = useState([]);
  const [selectedCliente, setSelectedCliente] = useState('');
  const [selectedClienteNombre, setSelectedClienteNombre] = useState('');
  const [obligaciones, setObligaciones] = useState([]);
  const [selectedObligacion, setSelectedObligacion] = useState('');
  const [fechaLimite, setFechaLimite] = useState('');
  const [nroTalonario, setNroTalonario] = useState('');
  const [envioPlanillas, setEnvioPlanillas] = useState('no');
  const [rcIva, setRcIva] = useState('trim');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', body: '' });
  const [redirect, setRedirect] = useState(false);

  useEffect(() => {
    const db = getDatabase();
    const clientesRef = ref(db, 'Clientes');

    onValue(clientesRef, (snapshot) => {
      const data = snapshot.val();
      const clientsList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      const activeClients = clientsList.filter(cliente => cliente.estado === 'Activo');
      setClientes(activeClients);
    });
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setRedirect(true);
      console.log('Sesión cerrada');
    } catch (error) {
      console.error('Error al cerrar sesión', error);
    }
  };

  const handleClienteChange = (e) => {
    const selected = e.target.value;
    setSelectedCliente(selected);

    const cliente = clientes.find(cliente => cliente.id === selected);
    if (cliente) {
      setSelectedClienteNombre(cliente.nombre);
      if (cliente.obligaciones) {
        setObligaciones(cliente.obligaciones);
      } else {
        setObligaciones([]);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const db = getDatabase();
      const newRecordRef = ref(db, 'AsignacionesPorCliente');
      await push(newRecordRef, {
        clienteId: selectedCliente,
        clienteNombre: selectedClienteNombre,
        obligacion: selectedObligacion,
        fechaLimite,
        nroTalonario,
        envioPlanillas,
        rcIva
      });

      setSelectedCliente('');
      setSelectedClienteNombre('');
      setSelectedObligacion('');
      setFechaLimite('');
      setNroTalonario('');
      setEnvioPlanillas('no');
      setRcIva('trim');

      setModalContent({ title: 'Registro Exitoso', body: 'Asignación registrada exitosamente.' });
      setShowModal(true);
    } catch (error) {
      let message = 'Ocurrió un error desconocido. Por favor, inténtalo de nuevo.';
      setModalContent({ title: 'Error de Registro', body: message });
      setShowModal(true);
    }
  };

  if (redirect) {
    return <Navigate to="/signin" />;
  }

  return (
    <div>
      <NavBar handleSignOut={handleSignOut} />
      <br />
      <Container>
        <h2>Registro por Obligaciones Cliente</h2>
        <Form onSubmit={handleSubmit}>
          <Form.Group>
            <Form.Label>Seleccionar Cliente</Form.Label>
            <Form.Control as="select" value={selectedCliente} onChange={handleClienteChange}>
              <option value="">Seleccione un cliente</option>
              {clientes.map(cliente => (
                <option key={cliente.id} value={cliente.id}>{cliente.nombre}</option>
              ))}
            </Form.Control>
          </Form.Group>

          {selectedCliente && obligaciones.length > 0 && (
            <Form.Group>
              <Form.Label>Seleccionar Obligación</Form.Label>
              <Form.Control as="select" value={selectedObligacion} onChange={(e) => setSelectedObligacion(e.target.value)}>
                <option value="">Seleccione una obligación</option>
                {obligaciones.map((obligacion, index) => (
                  <option key={index} value={obligacion}>{obligacion}</option>
                ))}
              </Form.Control>
            </Form.Group>
          )}

          {selectedCliente && obligaciones.length === 0 && (
            <p>El cliente seleccionado no tiene obligaciones asignadas.</p>
          )}

          <Form.Group>
            <Form.Label>Fecha Límite</Form.Label>
            <Form.Control type="date" value={fechaLimite} onChange={(e) => setFechaLimite(e.target.value)} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Nro Talonario Impreso</Form.Label>
            <Form.Control type="text" value={nroTalonario} onChange={(e) => setNroTalonario(e.target.value)} required />
          </Form.Group>
          <Form.Group>
            <Form.Label>Envío Planillas Min. Trabajo</Form.Label>
            <Form.Control as="select" value={envioPlanillas} onChange={(e) => setEnvioPlanillas(e.target.value)} required>
              <option value="no">No</option>
              <option value="si">Sí</option>
            </Form.Control>
          </Form.Group>
          <Form.Group>
            <Form.Label>RC IVA</Form.Label>
            <Form.Control as="select" value={rcIva} onChange={(e) => setRcIva(e.target.value)} required>
              <option value="trim">Trim</option>
              <option value="manual">Manual</option>
              <option value="linia/09">Linia/09</option>
              <option value="linia/08">Linia/08</option>
              <option value="baja">Baja</option>
            </Form.Control>
          </Form.Group>
          <Button type="submit" className="btn-primary">Registrar Asignación</Button>
        </Form>

        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>{modalContent.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent.body}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>Cerrar</Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </div>
  );
}

export default AsignacionesPorCliente;