import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import SignUp from './Components/Auth/SignUp';
import SignIn from './Components/Login/Sign';
import Dashboard from './Components/Portal/Dashboard';
import RegistrarCliente from './Components/Clientes/RegistrarCliente';
import ListaClientes from './Components/Clientes/ListaClientes';
import AsignacionObligaciones from './Components/Clientes/AsignacionObligaciones';
import Credenciales from './Components/Clientes/Credenciales';
import ClientesConAsignaciones from './Components/Seguimiento/ClientesConAsignaciones';
import AsignacionesporClientes from './Components/Seguimiento/AsignacionesporCliente';
import DeclaracionImpuestos from './Components/Seguimiento/DeclaracionImpuestos';

function App() {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      setCurrentUser(user);
      setLoading(false);
    });

  
    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div>Cargando...</div>;
  }

  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={currentUser ? <Dashboard /> : <Navigate replace to="/signin" />}   />
          <Route path="/signup" element={!currentUser ? <SignUp /> : <Navigate replace to="/" />} />
          <Route path="/signin" element={!currentUser ? <SignIn /> : <Navigate replace to="/" />} />
          <Route path="/registrar-cliente" element={currentUser ? <RegistrarCliente /> : <Navigate replace to="/signin" />} />
          <Route path="/lista-clientes" element={currentUser ? <ListaClientes /> : <Navigate replace to="/signin" />} />
          <Route path="/asignacion-obligaciones" element={currentUser ? <AsignacionObligaciones /> : <Navigate replace to="/signin" />} />
          <Route path="/clientes-asignaciones" element={currentUser ? <ClientesConAsignaciones /> : <Navigate replace to="/signin" />} />
          <Route path="/clientesxpc" element={currentUser ? <Credenciales /> : <Navigate replace to="/signin" />} />
          <Route path="/asignaciones-cliente" element={currentUser ? <AsignacionesporClientes /> : <Navigate replace to="/signin" />} />
          <Route path="/declaraciones-impuestos" element={currentUser ? <DeclaracionImpuestos /> : <Navigate replace to="/signin" />} />
          {/*<Route path="/RegistrarCaso" element={<Caso />} />*/}
          {/*<Route path="/ListarCasos" element={<ListarCaso />} />*/}
        </Routes>
      </div>
    </Router>
  );
}
export default App;
