import React from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, NavDropdown, Container, Button } from 'react-bootstrap';

function NavBar({ handleSignOut }) {
  return (
    <Navbar expand="lg" className="bg-body-tertiary">
      <Container fluid>
        <img
          src={process.env.PUBLIC_URL + '/img/chlogotrans.png'}
          width="30"
          height="30"
          className="d-inline-block align-top"
          alt="Logo"
        />
        <Navbar.Brand as={Link} to="/">Inicio</Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav className="me-auto my-2 my-lg-0" style={{ maxHeight: '100px' }} navbarScroll>
            <Nav.Link as={Link} to="/registrar-cliente">Registrar Cliente</Nav.Link>
            <NavDropdown title="Clientes" id="navbarScrollingDropdown">
              <NavDropdown.Item as={Link} to="/lista-clientes">Listar Clientes</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/asignacion-obligaciones">Asignaciones</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/clientesxpc">Credenciales</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Asignaciones" id="navbarScrollingDropdown">
              <NavDropdown.Item as={Link} to="/clientes-asignaciones">Estado Asignaciones</NavDropdown.Item>
              <NavDropdown.Item as={Link} to="/asignaciones-cliente">Registro Seguimiento</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item as={Link} to="/declaraciones-impuestos">Declaraciones Impuestos</NavDropdown.Item>
            </NavDropdown>
          </Nav>
          <Button variant="outline-danger" onClick={handleSignOut}>Cerrar Sesión</Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default NavBar;