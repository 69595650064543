import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, update } from 'firebase/database';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { Button, Form, Container, Modal, Table } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import NavBar from '../NavBar/navbar';
import './AsignacionObligaciones.css';

const entidadesRegulatorias = [
  "Elegir Entidad Regulatoria",
  "Superintendencia Agraria",
  "Superintendencia de Bancos y Entidades Financieras",
  "Superintendencia de Electricidad",
  "Superintendencia de Empresas",
  "Superintendencia de Hidrocarburos",
  "Superintendencia de Pensiones, Valores y Seguros",
  "Superintendencia de Recursos Naturales Renovables",
  "Superintendencia de Regulación Financiera",
  "Superintendencia de Servicio Civil",
  "Superintendencia de Telecomunicaciones",
  "Superintendencia de Transportes",
  "Superintendencia General",
  "Superintendencia Tributaria",
  "Registro de comercio",
  "Número de Identificación Tributaria",
  "Licencia de Funcionamiento",
  "Seguro de Salud Obligatorio",
  "Gestora",
  "Ministerio de Trabajo",
  "LCV",
  "IVA",
  "IT",
  "Obligaciones Tributarias"

];

function AsignacionObligaciones() {
  const [clientes, setClientes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('nit');
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [currentCliente, setCurrentCliente] = useState(null);
  const [selectedObligacion, setSelectedObligacion] = useState('');
  const [searchPerformed, setSearchPerformed] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showObligacionesModal, setShowObligacionesModal] = useState(false);
  const [obligaciones, setObligaciones] = useState([]);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [obligacionToDelete, setObligacionToDelete] = useState(null);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setRedirect(true);
      console.log('Sesión cerrada');
    } catch (error) {
      console.error('Error al cerrar sesión', error);
    }
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const db = getDatabase();
    const clientsRef = ref(db, 'Clientes');
    onValue(clientsRef, (snapshot) => {
      const data = snapshot.val();
      const clientsList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      const filtered = clientsList.filter(cliente => cliente[searchField].toLowerCase().includes(searchTerm.toLowerCase()) && cliente.estado === 'Activo');
      setClientes(filtered);
      setSearchPerformed(true);
    });
  };

  const handleAddObligacion = async () => {
    const db = getDatabase();
    const clientRef = ref(db, `Clientes/${currentCliente.id}`);
    const obligaciones = currentCliente.obligaciones || [];

    if (obligaciones.includes(selectedObligacion)) {
      setShowErrorModal(true);
      setShowModal(false);
      return;
    }

    const updatedObligaciones = [...obligaciones, selectedObligacion];

    await update(clientRef, { obligaciones: updatedObligaciones });
    setShowModal(false);
    setShowSuccessModal(true); // Mostrar modal de éxito
  };

  const handleViewObligaciones = (cliente) => {
    setCurrentCliente(cliente);
    setObligaciones(cliente.obligaciones || []);
    setShowObligacionesModal(true);
  };

  const confirmDeleteObligacion = (obligacion) => {
    setObligacionToDelete(obligacion);
    setShowConfirmDelete(true);
  };

  const handleDeleteObligacion = async () => {
    const db = getDatabase();
    const clientRef = ref(db, `Clientes/${currentCliente.id}`);
    const updatedObligaciones = obligaciones.filter(item => item !== obligacionToDelete);

    await update(clientRef, { obligaciones: updatedObligaciones });
    setObligaciones(updatedObligaciones);
    setShowConfirmDelete(false);
    setShowDeleteSuccessModal(true);
  };

  if (redirect) {
    return <Navigate to="/signin" />;
  }

  return (
    <div>
      <NavBar handleSignOut={handleSignOut} />
      <br />
      <Container style={{ maxWidth: '1200px' }}>
        <h2>Asignación de Obligaciones</h2>
        <Form onSubmit={handleSearch}>
          <Form.Group>
            <Form.Label>Buscar por:</Form.Label>
            <Form.Control as="select" value={searchField} onChange={(e) => setSearchField(e.target.value)}>
              <option value="nit">NIT</option>
              <option value="ci">CI</option>
            </Form.Control>
            <Form.Control type="text" placeholder={`Buscar por ${searchField}`} value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)} />
            <Button type="submit" className="btn-search">Buscar</Button>
          </Form.Group>
        </Form>
        <br />
        {searchPerformed && clientes.length === 0 && (
          <p>No hay resultados</p>
        )}
        {searchPerformed && clientes.length > 0 && (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Nombre</th>
                <th>Teléfono</th>
                <th>Tipo de Cliente</th>
                <th>NIT</th>
                <th>CI</th>
                <th>Correo Electrónico</th>
                <th>Estado</th>
                <th>Acciones</th>
              </tr>
            </thead>
            <tbody>
              {clientes.map(cliente => (
                <tr key={cliente.id}>
                  <td>{cliente.nombre}</td>
                  <td>{cliente.telefono}</td>
                  <td>{cliente.tipoCliente}</td>
                  <td>{cliente.nit}</td>
                  <td>{cliente.ci}</td>
                  <td>{cliente.correo}</td>
                  <td>{cliente.estado}</td>
                  <td>
                    <div className="btn-group">
                      <Button onClick={() => { setCurrentCliente(cliente); setShowModal(true); }} className="btn-assign">Asignar Obligación</Button>
                      <Button onClick={() => handleViewObligaciones(cliente)} className="btn-view">Ver Obligaciones</Button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        )}
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Asignar Obligación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Seleccionar Obligación</Form.Label>
              <Form.Control as="select" value={selectedObligacion} onChange={(e) => setSelectedObligacion(e.target.value)}>
                {entidadesRegulatorias.map(entidad => (
                  <option key={entidad} value={entidad}>{entidad}</option>
                ))}
              </Form.Control>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Cancelar</Button>
          <Button variant="primary" onClick={handleAddObligacion}>Asignar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showSuccessModal} onHide={() => setShowSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>La obligación ha sido registrada exitosamente.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowSuccessModal(false)}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showErrorModal} onHide={() => setShowErrorModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Error</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>La obligación ya está registrada para este cliente.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowErrorModal(false)}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showObligacionesModal} onHide={() => setShowObligacionesModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Obligaciones de {currentCliente?.nombre}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {obligaciones.length > 0 ? (
            <ul>
              {obligaciones.map((obligacion, index) => (
                <li key={index}>
                  {obligacion}
                  <Button variant="danger" onClick={() => confirmDeleteObligacion(obligacion)} className="btn-delete">Eliminar</Button>
                </li>
              ))}
            </ul>
          ) : (
            <p>No hay obligaciones registradas.</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowObligacionesModal(false)}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showConfirmDelete} onHide={() => setShowConfirmDelete(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmar Eliminación</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>¿Está seguro de que desea eliminar la obligación "{obligacionToDelete}"?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmDelete(false)}>Cancelar</Button>
          <Button variant="danger" onClick={handleDeleteObligacion}>Eliminar</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteSuccessModal} onHide={() => setShowDeleteSuccessModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Éxito</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>La obligación ha sido eliminada exitosamente.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowDeleteSuccessModal(false)}>Cerrar</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default AsignacionObligaciones;