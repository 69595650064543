import { initializeApp } from 'firebase/app';
import { getAuth,GoogleAuthProvider  } from 'firebase/auth';
import { getDatabase } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCPk403USwp43H2Zsp3c2Tl42WcS0GssUg",
    authDomain: "contboc-70130.firebaseapp.com",
    databaseURL: "https://contboc-70130-default-rtdb.firebaseio.com",
    projectId: "contboc-70130",
    storageBucket: "contboc-70130.appspot.com",
    messagingSenderId: "692464292791",
    appId: "1:692464292791:web:88e554c60cdb2827254e32"
  };
  
  const app = initializeApp(firebaseConfig);
  const auth = getAuth(app);
  const database = getDatabase(app);
  const googleProvider = new GoogleAuthProvider();
  
  export { auth, database, googleProvider };



