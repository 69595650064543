import React, { useState } from 'react';
import { getDatabase, ref, push } from 'firebase/database';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { Button, Form, Container, Modal } from 'react-bootstrap';
import { Navigate } from 'react-router-dom';
import NavBar from '../NavBar/navbar';
import './RegistrarCliente.css';

function RegistrarCliente() {
  const [nombre, setNombre] = useState('');
  const [telefono, setTelefono] = useState('');
  const [tipoCliente, setTipoCliente] = useState('personal');
  const [nit, setNit] = useState('');
  const [ci, setCi] = useState('');
  const [correo, setCorreo] = useState('');
  const [estado, setEstado] = useState('Activo');
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState({ title: '', body: '' });
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setRedirect(true); // Redirigir al usuario después de cerrar sesión
      console.log('Sesión cerrada');
    } catch (error) {
      console.error('Error al cerrar sesión', error);
    }
  };

  if (redirect) {
    return <Navigate to="/signin" />;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const db = getDatabase();
      const newClientRef = ref(db, 'Clientes');
      await push(newClientRef, {
        nombre,
        telefono,
        tipoCliente,
        nit,
        ci,
        correo,
        estado
      });
      setNombre('');
      setTelefono('');
      setTipoCliente('personal');
      setNit('');
      setCi('');
      setCorreo('');
      setEstado('Activo');

      setLoading(false);
      setModalContent({ title: 'Registro Exitoso', body: 'Cliente ha sido registrado exitosamente.' });
      setShowModal(true);
    } catch (error) {
      let message = 'Ocurrió un error desconocido. Por favor, inténtalo de nuevo.';
      setModalContent({ title: 'Error de Registro', body: message });
      setShowModal(true);
      setLoading(false);
    }
  };

  return (
    <div>
      <NavBar handleSignOut={handleSignOut} />
      <div className="background">
        <Container>
          <h2>Registrar Cliente</h2>
          <Form onSubmit={handleSubmit}>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" value={nombre} onChange={(e) => setNombre(e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Teléfono</Form.Label>
              <Form.Control type="text" value={telefono} onChange={(e) => setTelefono(e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tipo de Cliente</Form.Label>
              <Form.Control as="select" value={tipoCliente} onChange={(e) => setTipoCliente(e.target.value)} required>
                <option value="Personal">Personal</option>
                <option value="Empresa">Empresa</option>
                <option value="Unipersonal">Unipersonal</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>NIT</Form.Label>
              <Form.Control type="text" value={nit} onChange={(e) => setNit(e.target.value)} />
            </Form.Group>
            <Form.Group>
              <Form.Label>CI</Form.Label>
              <Form.Control type="text" value={ci} onChange={(e) => setCi(e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control type="email" value={correo} onChange={(e) => setCorreo(e.target.value)} required />
            </Form.Group>
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Form.Control as="select" value={estado} onChange={(e) => setEstado(e.target.value)} required>
                <option value="Activo">Activo</option>
                <option value="Deshabilitado">Deshabilitado</option>
                <option value="Cerrado">Cerrado</option>
              </Form.Control>
            </Form.Group>
            <Button type="submit" className="btn-primary" disabled={loading}>
              {loading ? 'Procesando...' : 'Registrar Cliente'}
            </Button>
          </Form>
          <Modal show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
              <Modal.Title>{modalContent.title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{modalContent.body}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={() => setShowModal(false)}>Cerrar</Button>
            </Modal.Footer>
          </Modal>
        </Container>
      </div>
    </div>
  );
}

export default RegistrarCliente;
