import React, { useState, useEffect, useMemo } from 'react';
import { getDatabase, ref, onValue, update } from 'firebase/database';
import { signOut } from 'firebase/auth';
import { auth } from '../../firebase';
import { Button, Form, Container, Modal } from 'react-bootstrap';
import { useTable, useSortBy } from 'react-table';
import * as XLSX from 'xlsx';
import { Navigate } from 'react-router-dom';
import NavBar from '../NavBar/navbar';
import './Credenciales.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

function Credenciales() {
  const [clientes, setClientes] = useState([]);
  const [filteredClientes, setFilteredClientes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchField, setSearchField] = useState('nombre');
  const [redirect, setRedirect] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [currentCliente, setCurrentCliente] = useState(null);
  const [showPasswords, setShowPasswords] = useState({});

  useEffect(() => {
    const db = getDatabase();
    const clientsRef = ref(db, 'Clientes');
    onValue(clientsRef, (snapshot) => {
      const data = snapshot.val();
      const clientsList = data ? Object.keys(data).map(key => ({ id: key, ...data[key] })) : [];
      const activeClients = clientsList.filter(cliente => cliente.estado === 'Activo');
      setClientes(activeClients);
      setFilteredClientes(activeClients);
    });
  }, []);

  const handleSignOut = async () => {
    try {
      await signOut(auth);
      setRedirect(true);
      console.log('Sesión cerrada');
    } catch (error) {
      console.error('Error al cerrar sesión', error);
    }
  };

  const handleSearch = (e) => {
    const value = e.target.value.toLowerCase();
    setSearchTerm(value);

    const filtered = clientes.filter(cliente =>
      cliente[searchField].toLowerCase().includes(value)
    );
    setFilteredClientes(filtered);
  };

  const handleExport = () => {
    const worksheet = XLSX.utils.json_to_sheet(filteredClientes);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Clientes');
    XLSX.writeFile(workbook, 'Clientes.xlsx');
  };

  const handleEdit = (cliente) => {
    setCurrentCliente(cliente);
    setShowModal(true);
  };

  const handleUpdate = () => {
    const db = getDatabase();
    const clientRef = ref(db, `Clientes/${currentCliente.id}`);
    update(clientRef, currentCliente).then(() => {
      setShowModal(false);
    }).catch((error) => {
      console.error('Error al actualizar el cliente', error);
    });
  };

  const togglePasswordVisibility = (id) => {
    setShowPasswords(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const columns = useMemo(
    () => [
      { Header: 'Nombre', accessor: 'nombre' },
      { Header: 'Teléfono', accessor: 'telefono' },
      { Header: 'Tipo de Cliente', accessor: 'tipoCliente' },
      { Header: 'NIT', accessor: 'nit' },
      { Header: 'CI', accessor: 'ci' },
      { Header: 'Correo Electrónico', accessor: 'correo' },
      { Header: 'Estado', accessor: 'estado' },
      { Header: 'Usuario', accessor: 'usuario' },
      { 
        Header: 'Contraseña', 
        accessor: 'password',
        Cell: ({ row }) => (
          <div>
            <span>{showPasswords[row.original.id] ? row.original.password : '*****'}</span>
            <FontAwesomeIcon 
              icon={showPasswords[row.original.id] ? faEyeSlash : faEye} 
              onClick={() => togglePasswordVisibility(row.original.id)}
              style={{ marginLeft: '10px', cursor: 'pointer' }}
            />
          </div>
        )
      },
      { 
        Header: 'Acciones', 
        id: 'acciones',
        Cell: ({ row }) => (
          <Button onClick={() => handleEdit(row.original)} className="btn-edit">Editar</Button>
        ) 
      },
    ],
    [showPasswords]
  );

  const tableInstance = useTable({ columns, data: filteredClientes }, useSortBy);

  if (redirect) {
    return <Navigate to="/signin" />;
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <div>
      <NavBar handleSignOut={handleSignOut} />
      <br />
      <Container style={{ maxWidth: '1400px' }}>
        <h2>Lista de Credenciales</h2>
        <Form>
          <Form.Group>
            <Form.Label>Buscar por:</Form.Label>
            <Form.Control as="select" value={searchField} onChange={(e) => setSearchField(e.target.value)}>
              <option value="nombre">Nombre</option>
              <option value="telefono">Teléfono</option>
              <option value="tipoCliente">Tipo de Cliente</option>
              <option value="estado">Estado</option>
            </Form.Control>
            <Form.Control type="text" placeholder={`Buscar por ${searchField}`} value={searchTerm} onChange={handleSearch} />
          </Form.Group>
        </Form>
        <Button onClick={handleExport} className="btn-export">Exportar a XLS</Button>
        <div className="table-container">
          <table {...getTableProps()} className="table">
            <thead>
              {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                      {column.render('Header')}
                      <span>
                        {column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}
                      </span>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map(row => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map(cell => (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    ))}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </Container>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Editar Cliente</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nombre</Form.Label>
              <Form.Control type="text" value={currentCliente?.nombre || ''} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Teléfono</Form.Label>
              <Form.Control type="text" value={currentCliente?.telefono || ''} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Tipo de Cliente</Form.Label>
              <Form.Control as="select" value={currentCliente?.tipoCliente || ''} disabled>
                <option value="Personal">Personal</option>
                <option value="Empresa">Empresa</option>
                <option value="Unipersonal">Unipersonal</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>NIT</Form.Label>
              <Form.Control type="text" value={currentCliente?.nit || ''} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>CI</Form.Label>
              <Form.Control type="text" value={currentCliente?.ci || ''} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Correo Electrónico</Form.Label>
              <Form.Control type="email" value={currentCliente?.correo || ''} disabled />
            </Form.Group>
            <Form.Group>
              <Form.Label>Estado</Form.Label>
              <Form.Control as="select" value={currentCliente?.estado || ''} disabled>
                <option value="Activo">Activo</option>
                <option value="Deshabilitado">Deshabilitado</option>
                <option value="Cerrado">Cerrado</option>
              </Form.Control>
            </Form.Group>
            <Form.Group>
              <Form.Label>Usuario</Form.Label>
              <Form.Control type="text" value={currentCliente?.usuario || ''} onChange={(e) => setCurrentCliente({ ...currentCliente, usuario: e.target.value })} />
            </Form.Group>
            <Form.Group>
              <Form.Label>Contraseña</Form.Label>
              <Form.Control type="password" value={currentCliente?.password || ''} onChange={(e) => setCurrentCliente({ ...currentCliente, password: e.target.value })} />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>Cancelar</Button>
          <Button variant="primary" onClick={handleUpdate}>Guardar Cambios</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default Credenciales;